body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.outlined-error {
  border-color: '#FF3232'
}

#ace_form_editor {
  height: 100% !important;
  width: auto !important;
}

button.jsoneditor-transform {
  display: none;
}

button.jsoneditor-extract {
  display: none;
}

button.jsoneditor-sort {
  display: none;
}

button.jsoneditor-sort-asc {
  display: none;
}

button.jsoneditor-type-array {
  display: none;
}

.bi.bi-diagram-node:nth-child(-n+2) {
  z-index: 1 !important;
  cursor: default !important;
}

.bi.bi-link-canvas-layer {
  z-index: 2 !important;
}