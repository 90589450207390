/* noto-sans-regular - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./noto-sans-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./noto-sans-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./noto-sans-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./noto-sans-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./noto-sans-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./noto-sans-v25-latin-regular.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* noto-sans-700 - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./noto-sans-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./noto-sans-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./noto-sans-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./noto-sans-v25-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./noto-sans-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./noto-sans-v25-latin-700.svg#NotoSans') format('svg'); /* Legacy iOS */
}