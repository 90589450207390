.App {
  text-align: left;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.blink_me {
  background: rgba(255, 89, 107, 0.2) !important;
  transition: background 0.5s linear;
  /* animation: blinker 1.5s linear infinite; */
}

/* @keyframes blinker {
  50% {
    background: #ff7e8d88;
  }
} */